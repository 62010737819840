import "../core/src/components/page-list/view/edit-collection/edit-collection.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/page-list/view/edit-collection/edit-collection.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81Wy3LbIBTd+yvYdKZdkJFUJXXJx3SwQBYJAgIoltvpv3dASAEbO5ZX3fgB933PPZeHX2XFHyv7VoA/GwDkO9UtlwcEOkYIFc8bACwdLfy4oJwzZZhxV4eOWQqNwg1FQMiDxup583fzMBstvVHCjOL4iEDL6ejUXgZjWXuEjRSWCouAtwB31B7o5HOPFQJb5aUxZ3sBmaW9QaChwlLtjhUmhIk9AuWTGqePbfiRxFDlY3DfkDBNG8ukQKCRfOi97wMjtkOgLIov7m9H2b6zH//PSxQ5+37ZGQLlp+p1Xv3TakQmHu8t+VLPqlAjqOrJ3U5qQjW0UiFQqhEYyRkB71h/hRC3LRMUBpFGcqm/LaV1WEBgQkTIoKxOWvPkY22lsNCw3xSBMnj1R4dQ+Loo3Bl3vuZmVNUk6J2iNB6PV0MbKQjWxyiuJcN6wck9CUYJ/JgS8N2trqMqGOG0tbdXcgYLHOOBXE6PCODByiSk7cU5Tor65Ioaqf30akuFihluKwocWcN5EF6YZA+OuPMBDKHHaeOL6fDqGO1WuQ/z3uNxng7fL9y87rUcBIG5GkTXSrM+BVqu+tMEBNBleXPGyO3wCAcaEzaYhSESmDtKrE/GrlnfnbI4b0+da8/piJOLcFzByDH/eSnTaSZeEUgxTFMMO/x8LIeVLm9l7PY/2HD7iIXKlXleTa7LJ5cll9l7pM7Wbt501UaWXrylHus9E4FCJyKIZF69TLK8o1t+9bb3t7k5Px3uSEmcba9rhHUfkcqbA5sjsUdOEWAWc9acsf72hPXVRfOskSIX0JvXGAzV0FBOG+v4S9AMGwV6aAZtnHUl2dlb5Q11Dkve5Cd86wVzAel06P1qr5LVntBjpGlW+oUt45yS1L1N3Sdvp2yVIrzM0EjxUt/xsgmZ7qS1sr/5HfMP8NsaVfwLAAA=\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var bottomButton = '_12l52tqr';
export var bottomLeft = '_12l52tq4';
export var button = '_12l52tqq';
export var collectionEditContainer = '_12l52tqi';
export var confirmButton = '_12l52tqj';
export var ellipsis = '_12l52tq0';
export var icon = '_12l52tqp';
export var includeItem = '_12l52tqb';
export var includeItemContent = '_12l52tqa';
export var includeItemContentIs = '_12l52tq9';
export var includeItemTitle = '_12l52tq8';
export var includeListTitle = '_12l52tq6';
export var includeTitle = '_12l52tqc';
export var pageList = '_12l52tql';
export var pagesList = '_12l52tq3';
export var pagesTab = '_12l52tq2';
export var pagesTabContent = '_12l52tq1';
export var previewActive = '_12l52tqs';
export var previewCountTips = '_12l52tqn';
export var previewCountTipsHighlight = '_12l52tqm';
export var resultPages = '_12l52tqk';
export var rulesBottom = '_12l52tq5';
export var rulesContainer = '_12l52tqh';
export var rulesContainerLeft = '_12l52tqg';
export var rulesContainerLeftContent = '_12l52tqe';
export var rulesContainerLeftContentInclude = '_12l52tqd';
export var rulesContainerLeftTab = '_12l52tqf';
export var rulesContainerRight = '_12l52tq7';
export var rulesTitle = '_12l52tqt';
export var rulesTitleHighlight = '_12l52tqo';